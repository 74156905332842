
.MobileOnly {
  max-width: 696px;
}

.RegisterOnly {
  max-width: 596px;
}

.TextStrong {
  font-weight: 500;
}

.TextColorGray {
  color: gray;
}

.TextColorGreen {
  color: #32c36c;
}

.TextColorCoffee1 {
  color: #38220f;
}

.TextColorCoffee2 {
  color: #634832;
}

.TextColorCoffee3 {
  color: #967259;
}

.TextColorCoffee4 {
  color: #dbc1ac;
}

.TextColorCoffee5 {
  color: #ece0d1;
}


/* button style */
.BtnColorGreen1 {
  background-color: #32c36c;
  border-color: #32c36c;
}

.BtnColorGreen1:hover  {
  background-color: #30b665;
  border-color: #30B665FF;
}

.BtnColorGreen1:hover:after  {
  background-color: #9a765c;
  border-color: #9a765c;
}

.BtnColorGreen1:active  {
  background-color: #8d684a !important;
  border-color: #8d684a !important;
}

.BtnColorGreen1:disabled  {
  background-color: #9a765c;
  border-color: #9a765c;
}





.BtnColorCoffee1 {
  background-color: #8d684a;
  border-color: #8d684a;
}

.BtnColorCoffee1:hover  {
  background-color: #9a765c;
  border-color: #9a765c;
}

.BtnColorCoffee1:hover:after  {
  background-color: #9a765c;
  border-color: #9a765c;
}

.BtnColorCoffee1:active  {
  background-color: #8d684a !important;
  border-color: #8d684a !important;
}

.BtnColorCoffee1:disabled  {
  background-color: #9a765c;
  border-color: #9a765c;
}


.BtnColorCoffee2 {
  background-color: #38220f;
  border-color: #38220f;
}

.BtnColorCoffee3 {
  background-color: #38220f;
  border-color: #38220f;
}

.BtnColorCoffee4 {
  background-color: #38220f;
  border-color: #38220f;
}

.BtnColorCoffee5 {
  background-color: #38220f;
  border-color: #38220f;
}

/* button style */

.BgColorCoffee1 {
  background-color: #38220f !important;
}

.BgColorCoffee2 {
  background-color: #634832 !important;
}

.BgColorCoffee3 {
  background-color: #967259 !important;
}

.BgColorCoffee4 {
  background-color: #dbc1ac !important;
}

.BgColorCoffee5 {
  background-color: #ece0d1 !important;
}

.BgColorCoffee6 {
  background-color: #bb877a !important;
}

.BgColorCoffee7 {
  background-color: #d5a294 !important;
}

.BgColorCoffee8 {
  background-color: #debcb2 !important;
}

.BgColorCoffee9 {
  background-color: #f3e2d6 !important;
}

.BgColorCoffee10 {
  background-color: #f5ebe7 !important;
}


.BgGradient1 {
  background: rgb(81,158,61);
  background: linear-gradient(180deg, #637aad 0%, #54a987 100%);
}
